export const leftMenus = [
  {
    name: '归因数据',
    code: 'top_attribution',
    children: [
      {
        name: 'Android归因',
        role: 3,
        code: '/manage/attribution_android_date_list_new'
      },
      {
        name: 'Android渠道上传数据',
        role: 3,
        code: '/manage/attribution_android_reported_data_list'
      },
      {
        role: 3,
        name: 'Android创意管理',
        code: '/manage/attribution_android_ad_setting_list'
      },
      {
        name: 'Android投放设置',
        code: '/manage/attribution_android_delivery_setting_list'
      },
      {
        name: 'Android渠道',
        code: '/manage/attribution_android_channel_setting_list'
      }
    ]
  },
  {
    name: '用户数据',
    code: 'top_user',
    children: [
      {
        name: '自营用户',
        code: '/manage/user_list'
      },
      {
        name: '自营订单',
        code: '/manage/order_list'
      },
    ]
  },
  {
    name: '分销管理',
    code: 'top_fenxiao',
    children: [
      {
        name: '激活码管理',
        code: '/manage/fenxiao/redeem_channel_list'
      },
      {
        name: '联合兑换码',
        code: '/manage/fenxiao/union_redeem_code_list'
      }
    ]
  },
  {
    name: 'AI管理',
    code: 'top_ai',
    children: [
      {
        name: '提示词',
        code: '/manage/ai/ai_action_config_list'
      },
      {
        name: '任务日志',
        code: '/manage/ai/ai_action_record_list'
      },
    ]
  },
  // {
  //   name: '频道数据',
  //   code: 'channel',
  //   children: [
  //     {
  //       name: '频道管理',
  //       code: '/manage/short_channel_list'
  //     }
  //   ]
  // },
  // {
  //   name: '剧集数据',
  //   code: 'short',
  //   children: [
  //     {
  //       name: '剧集管理',
  //       code: '/manage/short_list'
  //     }
  //   ]
  // },
  // {
  //   name: '订单数据',
  //   code: 'top_user',
  //   children: [
  //     {
  //       name: '商品设置',
  //       code: '/manage/product_list'
  //     }
  //   ]
  // },
  // {
  //   name: '通用设置',
  //   code: 'top_app',
  //   children: [
  //     {
  //       name: '系统参数',
  //       code: '/manage/setting_list'
  //     },
  //     {
  //       name: '政策设置',
  //       code: '/manage/privilege_list'
  //     }
  //   ]
  // }
]
