import ManageUserManager from '@/app/admin/screens/UserManager.vue'
import ShortChannelListScreen from '@/app/admin/screens/ShortChannelListScreen.vue'
import ShortListScreen from '@/app/admin/screens/ShortListScreen.vue'
import ShortChannelRecordListScreen from '@/app/admin/screens/ShortChannelRecordListScreen.vue'
import ShortEpisodeListScreen from '@/app/admin/screens/ShortEpisodeListScreen.vue'
import UserDetailScreen from '@/app/admin/screens/UserDetailScreen.vue'
import SettingListScreen from '@/app/admin/screens/SettingListScreen.vue'
import ProductListScreen from '@/app/admin/screens/ProductListScreen.vue'
import PrivilegeListScreen from '@/app/admin/screens/PrivilegeListScreen.vue'
import AttributionAndroidDateListScreen from '@/app/admin/screens/attribution/AttributionAndroidDateListScreen.vue'
import AttributionADSettingListScreen from '@/app/admin/screens/attribution/ADSettingListScreen.vue'
import AttributionChannelSettingListScreen from '@/app/admin/screens/attribution/ChannelSettingListScreen.vue'
import AttributionDeliverySettingListScreen from '@/app/admin/screens/attribution/DeliverySettingListScreen.vue'
import ManageOrderManager from '@/app/admin/screens/OrderManager.vue'
import AttributionAndroidReportedDataListScreen from '@/app/admin/screens/attribution/AttributionAndroidReportedDataListScreen.vue'
import RedeemChannelListScreen from '@/app/admin/screens/fenxiao/RedeemChannelListScreen.vue'
import RedeemCodeListScreen from '@/app/admin/screens/fenxiao/RedeemCodeListScreen.vue'
import AiActionRecordListScreen from '@/app/admin/screens/ai/AiActionRecordListScreen.vue'
import AiActionConfigListScreen from '@/app/admin/screens/ai/AiActionConfigListScreen.vue'
import UnionRedeemCodeListScreen from '@/app/admin/screens/fenxiao/UnionRedeemCodeListScreen.vue'

export default [
  {
    path: 'manage/user_list',
    name: 'manage_user_list',
    component: ManageUserManager
  },
  {
    path: 'manage/user_detail/:id',
    name: 'manage_user_detail',
    component: UserDetailScreen
  },
  {
    path: 'manage/short_channel_list',
    name: 'short_channel',
    component: ShortChannelListScreen
  },
  {
    path: 'manage/short_channel_list/:id/records',
    name: 'short_channel_record_list',
    component: ShortChannelRecordListScreen
  },
  {
    path: 'manage/short_list',
    name: 'short_list',
    component: ShortListScreen
  },
  {
    path: 'manage/short_list/:id/episodes',
    name: 'short_episode_list',
    component: ShortEpisodeListScreen
  },
  {
    path: 'manage/privilege_list',
    name: 'manage_privilege_list',
    component: PrivilegeListScreen
  },
  {
    path: 'manage/setting_list',
    name: 'manage_setting_list',
    component: SettingListScreen
  },
  {
    path: 'manage/product_list',
    name: 'manage_product_list',
    component: ProductListScreen
  },
  {
    path: 'manage/attribution_android_date_list_new',
    name: 'manage_attribution_android_date_list_new',
    component: AttributionAndroidDateListScreen
  },
  {
    path: 'manage/attribution_android_ad_setting_list',
    name: 'manage_attribution_android_ad_setting_list',
    component: AttributionADSettingListScreen
  },
  {
    path: 'manage/attribution_android_channel_setting_list',
    name: 'manage_attribution_android_channel_setting_list',
    component: AttributionChannelSettingListScreen
  },
  {
    path: 'manage/attribution_android_delivery_setting_list',
    name: 'manage_attribution_android_delivery_setting_list',
    component: AttributionDeliverySettingListScreen
  },
  {
    path: 'manage/order_list',
    name: 'manage_order_list',
    component: ManageOrderManager
  },
  {
    path: 'manage/attribution_android_reported_data_list',
    name: 'manage_attribution_android_reported_data_list',
    component: AttributionAndroidReportedDataListScreen
  },
  {
    path: 'manage/fenxiao/redeem_channel_list',
    name: 'manage_fenxiao_redeem_channel_list',
    component: RedeemChannelListScreen
  },
  {
    path: 'manage/fenxiao/redeem_code_list/:id',
    name: 'manage_fenxiao_redeem_code_list',
    component: RedeemCodeListScreen
  },

  {
    path: 'manage/ai/ai_action_record_list',
    name: 'manage_ai_action_record_list',
    component: AiActionRecordListScreen
  },
  {
    path: 'manage/ai/ai_action_config_list',
    name: 'manage_ai_action_config_list',
    component: AiActionConfigListScreen
  },
  {
    path: 'manage/fenxiao/union_redeem_code_list',
    name: 'manage_fenxiao_union_redeem_code_list',
    component: UnionRedeemCodeListScreen
  },
]
